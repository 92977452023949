
export default {
  name: "GlobalForm",
  props: {
    showDeleteBtn: {
      type: Boolean,
      default: true,
    },
    showLoginBtn: {
      type: Boolean,
      default: true,
    },
    showRegisterBtn: {
      type: Boolean,
      default: true,
    },
    // 表单配置
    formConfig: {
      type: Array,
      required: true,
    },
    // 表单数据
    formData: {
      type: Object,
      required: true,
    },
    // 表单规则
    rules: {
      type: Object,
    },
    submitLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      flag: false,
    };
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit("handleSubmit");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.$emit("handleClose");
    },
    showPassword() {
      this.flag = !this.flag;
    },
  },
};
